@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.cdnfonts.com/css/akrobat');
@import 'normalize.css';

/** Defaults */
* {
  margin: 0;
  padding: 0;
  border: none;
  position: relative;
}

html,
body {
  height: 100vh;
  width: 100vw;
  color: black;
  overflow: hidden;
  font-size: 14px;
  /** 1em ou 1rem === 14px */
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}

/** Defaults */

/** card-titulo */
.card-titulo {
  color: #000 !important;
  background: #fff !important;
}

/** content */
.content {
  box-shadow: inset -2px 0px 2px #00000012 !important;
}

/** DataTable */
.ag-row-selected {
  border-bottom: 1px solid #223771 !important;
}

.ag-row-odd:not(.ag-row-selected) {
  background-color: rgba(195, 210, 227, 0.12) !important;
  border-bottom: 1px solid #c3d2e3 !important;
}

.ag-row-even:not(.ag-row-selected) {
  background-color: #ffffff !important;
  border-bottom: 1px solid #c3d2e3 !important;
}

/** botão de editar */
.button-grid {
  margin-right: 30px !important;
}

/** sombra da tabela */
.tabela .MuiPaper-elevation {
  box-shadow: none !important;
}

/** borda paginação */
.MuiTableCell-root {
  border: none !important;
}

.ag-center-cols-viewport {
  height: calc(100% + 18px) !important;
}

/* fonte */
.ag-header-cell-text {
  font-family: 'Open Sans' !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 19px !important;
}

/** border-bottom da tabela */
.ag-theme-material {
  border-bottom: 2px solid #c3d2e3 !important;
  box-shadow: none !important;
}

.ag-cell-label-container {
  height: 100%;
  font-size: 14px !important;
  font-weight: 400;
  cursor: pointer;
  color: #95a2b9;
  opacity: 1 !important;
  margin-bottom: 11px !important;
}

.ag-header-row {
  border: none !important;
  box-sizing: border-box !important;
  border-width: 0px 0px 2px !important;
  padding-top: 15px !important;
  height: 50px !important;
}

.MuiButton-containedPrimary .MuiButton-disableElevation {
  margin-right: 30px !important;
}

.ag-cell-not-inline-editing {
  color: #1b1d26 !important;
  font-family: 'Open Sans';
}

.ag-header-viewport {
  border-bottom: 2px solid #c3d2e3 !important;
  background-color: rgba(195, 210, 227, 0.25) !important;
  border-radius: 0px !important;
  border-top: 1px solid #c3d2e3 !important;
  height: 50px !important;

}

.ag-pinned-left-header {
  border-bottom: 2px solid #c3d2e3 !important;
  background-color: rgba(195, 210, 227, 0.25) !important;
  border-radius: 0px !important;
  border-top: 1px solid #c3d2e3 !important;
  height: 50px !important;
}

.resumo .ag-header-viewport {
  margin: 0px 0px !important;
}

.resumo .ag-body-viewport {
  margin: 0 0px !important;
}

.ag-header-active {
  background-color: transparent !important;
}

.MuiTablePagination-selectLabel,
.MuiTablePagination-displayedRows {
  font-family: 'Open Sans' !important;
  color: #5b6478 !important;
  font-size: 12px !important;
  line-height: 17px !important;
}

.ag-header {
  border: none !important;
  min-height: 50px !important;
  height: 50px !important;
}

.ag-root.ag-layout-normal {
  margin: 0px 23px !important;
}

.ag-paging-panel {
  border-style: solid !important;
  border-color: rgba(0, 0, 0, 0.1) !important;
  box-sizing: border-box !important;
  border-width: 0px 0px 2px !important;
}

/** DataTable */

.suggestions {
  max-height: 400px !important;
}

.suggestions-pais {
  max-height: 280px !important;
  width: 150% !important
}

.suggestions-genero-produto {
  max-height: 280px !important;
  width: 350% !important
}

/* Custom Scrollbar */
/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(9, 9, 9, 0);
  border-radius: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 0px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.6);
}

/* Custom Scrollbar */

.submenu span {
  font-size: 12px !important
}