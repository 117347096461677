/** CardFilters **/
/** botão filtrar */

/** card-filters-filtrar */
.card-filters-filtrar button {
  border-radius: 16px !important;
  background: rgba(92, 120, 199, 0.22) !important;
  padding: 6px 18px !important;
  font-size: 14px !important;
  color: #223771 !important;
  text-align: center;
  height: 32px !important;
  min-width: 98px !important;
  font-weight: 600 !important;
  font-family: 'Open sans' !important;
  bottom: 6px !important;
}

/** label filtros */
.expanded-icon-label {
  font-size: 18px !important;
  margin-right: 10px !important;
}

/** margem do header */
.card-filters-header {
  margin: 0px !important;
}

/** espaçamento botões de ação */
.card-filters-actions .MuiButton-root {
  padding: 0px !important;
  min-width: 24px !important;
  margin-right: 27px !important;
}

/** container dos inputs */
.card-filters-inputs-container {
  padding: 20px 25px 0 !important;
  height: 97px !important;
}

/** tamanho dos inputs */
.card-filters-inputs-container .MuiGrid-item {
  padding-right: 27px !important;
}

/** altera o primeiro select */
.card-filters-tipo-filtro {
  flex-basis: 0% !important;
  min-width: 202px !important;
}

.card-filters-clean {
  color: #f7375c !important;
}

.card-filters-footer {
  padding: 0 25px 12px 25px !important;
}

/** adicionando margens aos chips quando o cardFilters está aberto */
.MuiAccordionActions-spacing .MuiChip-root {
  margin: 0px 6px 6px !important;
}

/** label filtros adicionados */
.label-filter-chips-closed {
  margin-bottom: 6px !important;
  margin-right: 6px !important;
  color: #95a2b9 !important;
  font-size: 12px !important;
  line-height: 17px !important;
}

/* altura do header */
.card-filters-header {
  min-height: 48px !important;
  max-height: 48px !important;
  padding: 0 25px !important;
}

/** label do chip */
.MuiChip-root span {
  font-size: 12px;
  font-family: 'Open Sans';
  color: #33384f !important;
  line-height: 17px !important;
}

/** titulo do chip */
.MuiChip-root b {
  font-weight: bold;
}

/** CardFilters **/
