/** LoadingSwal **/
.container-swal {
  width: 100px;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/** LoadingSwal **/

/** Swal **/
.container-label {
  width: 450px;
}
/** Swal */
